/**
 * Custom validation attributes.
 *
 * Todo: Figure out how we can get this loading!
 *
 * It currently adds the validation adapter after the validator has been created
 * as unobtrusive validation automatically calls $.validator.unobtrusive.parse(document);
 *
 * We can manually add to the rules, for a specific field, like this:
 *
 *  $("#UserForm_Password").rules("add", {
 *      requiredfornewusers: true,
 *      messages: {
 *          requiredfornewusers: "Required input"
 *      }
 *  });
 *
 * But it would be nicer to have a generic ruleset applied to all forms.
 */
interface IAdapterOptions {
    element: HTMLElement;
    form: HTMLFormElement;
    message: string;
    messages: { [key: string]: string };
    params: { [key: string]: any[] };
    rules: { [key: string]: any };
}

const requiredForNewUsersAttribute = "requiredfornewusers";

$.validator.addMethod(requiredForNewUsersAttribute, function (value, element, params) {
    return (params.userId > 0 || value !== null && value.length > 0)
});

$.validator.unobtrusive.adapters.add(requiredForNewUsersAttribute, [], function (options: IAdapterOptions) {
    const element = options.form.querySelector<HTMLInputElement>("#UserForm_UserId");
    const userId = parseInt(element.value);

    options.rules[requiredForNewUsersAttribute] = { userId: userId };
    options.messages[requiredForNewUsersAttribute] = options.message;
});
